<template>
  <div>
    <v-banner class="lavBanner" single-line height="400" color="#F5F8FD">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/customeSolutionBanner.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center mt-16" style="color:#FFF">
              <div>
                <v-card-title class="font-size-48 white--text">
                  技术+私域+运营赋能，<br/>致力于品牌更深度的服务 
                </v-card-title>
                <v-card-title class="font-size-18 justify-center white--text">
                  一站式解决活动、营销、运营痛点，专注大客户服务
                </v-card-title>
`                <v-card-actions class="pa-0 justify-center" style="margin:0 auto;">
                  <!-- <v-btn
                      rounded
                      width="160"
                      height="50"
                      color="#0568FD"
                      class="font-size-20"
                  >
                    <span style="color: #ffffff;">立即咨询</span>
                  </v-btn> -->
                </v-card-actions> 
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 产品功能介绍 -->
      <!-- <v-container class="d-flex justify-center" > -->
        <v-card class=" warp2" :flat="true" style="width:1200px;margin:0 auto;d-flex justify-center">
          <div class="public-title" style="margin:70px 0 30px 0">
            <p>SERVICE INTRODUCTION</p>
            <div>
              <span>产品功能介绍</span>
              <span>服务活动营销全链路，一体化赋能营销目标</span>
            </div>
          </div>
          <v-tabs v-model="tab" centered  class="g-tabs">
            <v-tab v-for="(item,index) in data.items" :key="index">
              <v-card-title class="font-size-20" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.tab }}</v-card-title>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item,index) in data.items" :key="index" :transition="false">
                <div class="box">
                  <transition name="slide-fade" appear>
                    <v-img v-show="tab === index" :src="item.src" width="400" height="260" class="rounded-xl">
                      <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                          <v-progress-circular
                              indeterminate
                              size="80"
                              width="8"
                              color="grey lighten-3"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </transition>
                  <transition name="slide-fade-reverse" appear>
                    <div class="right px-5  ml-16" v-show="tab === index">
                      <v-card-title class="title px-0 py-1" v-text="item.title" />
                      <v-card-text class="tip" v-text="item.content" />
                    </div>
                  </transition>
                </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      <!-- </v-container> -->

    <!-- 产品优势 -->
    <v-card flat class="con2">
      <v-container style="max-width:1200px">
        <div class="public-title" style="margin-top:70px;">
            <p>PRODUCT ADVANTAGES</p>
            <div>
              <span>产品优势</span>
            </div>
          </div>
          <v-row class="justify-center">
            <v-col cols="3" v-for="(card,index) in data.cardList" :key="index">
              <v-card
                  height="100%"
                  class="cardStyle" elevation="0">
                <div class="d-flex justify-center">
                  <v-img
                      :src="card.src"
                      class="white--text align-end flex-grow-0"
                      height="100"
                      width="100"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                  <v-card-title class="justify-center font-size-20">{{card.title}}</v-card-title>
                  <v-card-text class="font-size-14 text-align-center" v-text="card.content"></v-card-text>
              </v-card>
            </v-col>
          </v-row>
      </v-container>
    </v-card>
    <!-- 配套服务 -->
    <v-container>
      <div class="public-title" style="margin-top:70px;margin-bottom:-10px">
          <p>SUPPORTING SERVICE</p>
          <div>
            <span>配套服务</span>
          </div>
      </div>
      <v-row class="mt-4 max-width-1200" style="margin:0 auto">
        <v-col cols="3" v-for="(card,index) in data.peitaoList" :key="index" class="d-flex child-flex">
            <div class="box-img rounded-lg" :style="{backgroundImage: 'url('+card.src+')'}">
              <div>{{ card.title }}</div>
            </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- 合作客户 -->
    <v-card flat class="con3">
      <div style="height:1px;margin-top:50px;"></div>
      <div class="public-title" style="height:unset;">
        <p>COOPERATE</p>
        <div>
          <span>合作客户</span>
        </div>
      </div>
      <div class="box mt-3">
        <v-img
          src="https://h5.ophyer.cn/official_website/other/home-hzhb3.png" contain
        />
      </div>
    </v-card>
  </div>
</template>

<script>
let _this;
import textData from '@/static/textData.js' // 合作案例
import lavData from '@/static/lavData.js'
import qs from 'qs'
export default {
  data: () => ({
    imgLoad: false,
    windowHight: window.innerHeight/2,
    data:lavData.customersHome,
    anli: [textData.anli.wangshangchufang,textData.anli.meilizhongguo,textData.anli.quanjingqiche,textData.anli.baicaiershouche],
    tab: null,
  }),
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    },
    goCaseDetail(item){
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
}
};
</script>

<style lang="scss" scoped>
.con3 {
  width: 100%;
  background-color: #f5f9fd;
  .box {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 30px;
    // margin-top:50px;
  }
} 

.con2{
  background-color: #f5f9fd;
  padding: 12px 0 30px 0;
  .cardStyle{
    background-color: unset;
  }
}
.lavBanner {
  border: none !important;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .bannerContent {
    height: 100%;
    display: flex;
    color: #ffff;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }
}
.warp2 {
  .box {
    width: 1000px;
    margin: 40px auto;
    display: flex;
    align-items: center;
  }
  .left {
    margin-right: 110px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    margin-right: 8+0px;
    .v-card__text {
      padding: 0;
      line-height: 30px!important;
    }
    .title {
      font-size: 24px !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 14px !important;
      color:#666!important;
      opacity:1;
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
::v-deep .v-card__text{
  line-height: 20px!important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.04);
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 20px;
      right: 10px;
  }
}
.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-img {
  width: 240px;
  height: 160px;
  background-size: cover;
  overflow: hidden;
  transition: all 0.3s ease-in;
  div {
    width: 100%;
    height: 100%;
    background-color:rgba(25, 26, 27, 0.5);
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    transform: scale(1.1);
  }
}
</style>